import retina from 'retinajs';
window.addEventListener('load', retina);

import Modal from 'bootstrap';
import 'jquery-match-height';
import Nav from './components/nav';
import Wave from './components/wave';
import Carousel from './components/carousel';
import Cookies from 'js-cookie';

if (!("ontouchstart" in document.documentElement)) {
document.documentElement.className += " no-touch";
}

new Nav();
new Wave();
new Carousel();

// function getcookie(name = '') {
//     let cookies = document.cookie;
//     let cookiestore = {};

//     cookies = cookies.split(";");

//     if (cookies[0] == "" && cookies[0][0] == undefined) {
//         return undefined;
//     }

//     cookies.forEach(function(cookie) {
//         cookie = cookie.split(/=(.+)/);
//         if (cookie[0].substr(0, 1) == ' ') {
//             cookie[0] = cookie[0].substr(1);
//         }
//         cookiestore[cookie[0]] = cookie[1];
//     });

//     return (name !== '' ? cookiestore[name] : cookiestore);
// }

$(window).on('load', function() {
    $('.m2g-menu-product-container').matchHeight();
});

$('#pocha-signup').modal('hide');

// $('#pocha-signup').on('hidden.bs.modal', function (e) {
//   Cookies.set('signup', { expires: 7 });
// });

// // Local Cookie
// document.cookie = "desktopSignup";
// var signUpPopUp = getcookie("desktopSignup");
// if(signUpPopUp == "on") {
//     $('#pocha-signup').modal('hide');
// } else {
//     $('#pocha-signup').modal('show');
// }
// // js-cookie for mobile
// if( Cookies.get('signup') ) {
//     $('#pocha-signup').modal('hide');
// } else {
//     $('#pocha-signup').modal('show');
// }

