import 'slick-carousel';
import 'jquery-match-height';

export default class Carousel {
  constructor(userOptions = {}, slickOptions = {}) {
    var options = Object.assign({
      selector: '.slick-carousel',
      slickOptions: Object.assign({
        autoPlay: false,
      }, slickOptions)
    }, userOptions);
    $(options.selector).slick(options.slickOptions);
    // Hero banner slider
    $('.pocha-block-hero-banner-slider').slick({
      arrows: false,
      autoplay: true
    });
    // pocha menu feature slider block
    $('.pc-menu-card-feature__slider').on('init breakpoint', function (event) {
        $('.pc-menu-card-feature__slider article').matchHeight();
    });
    $('.pc-menu-card-feature__slider').slick({
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplay: false,
        // appendArrows: '.pc-menu-card-feature__arrow',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    appendArrows: '.pc-menu-card-feature__arrow',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    appendArrows: '.pc-menu-card-feature__arrow',
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            }
        ]
    });
    // pocha menu slider block
    $('.pc-menu-card__slider').on('init breakpoint', function (event) {
        $('.pc-menu-card__slider article').matchHeight();
    });
    $('.pc-menu-card__slider').slick({
        slidesToShow: 5,
        slidesToScroll: 5,
        autoplay: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4
                }
            }
        ]
    });
    // pocha-block-hero-grid block - mobile swaps to carousel
    $(".pc-hero-grid__slider").slick({
        dots: true,
        arrows: false,
        vertical: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        verticalSwiping: true,
        adaptiveHeight: false
    });
  }
}
