export default class Wave {
  constructor() {
    document.addEventListener(
      "DOMContentLoaded", () => {
        let xs = []
        for (var i = 0; i <= 10000; i++) {
          xs.push(i)
        }

        let t = 0

        function animate() {

          let points = xs.map(x => {
            let y = 54 + 50 * Math.sin((x + t) / 50)
            return [x, y]
          })
          let pointsMobile = xs.map(x => {
            let y = 25 + 20 * Math.sin((x + t) / 20)
            return [x, y]
          })

          let path = "M" + points.map(p => {
            return p[0] + "," + p[1]
          }).join(" L")

          let pathMobile = "M" + pointsMobile.map(p => {
            return p[0] + "," + p[1]
          }).join(" L")

          document.querySelector("path.pocha-wave").setAttribute("d", path)
          document.querySelector("path.pocha-wave-mobile").setAttribute("d", pathMobile)
          t += 0.9
          requestAnimationFrame(animate)
        }

        animate()
      }
    );
  }
}




